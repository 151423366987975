import * as React from "react";
import Seo from "../components/seo";
import IndexContainer from "../containers/index";
import { translations } from "../translations";
import type { Translation } from "../types/main";

const t: Translation = {
	language: "en",
	translations: translations.en,
};

const IndexPage = () => (
	<>
		<Seo title="Flowby - Cut your next line" lang="en" />
		<IndexContainer t={t} />
	</>
);

export default IndexPage;
